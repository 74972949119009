/* @import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

.footer-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #000;
  padding: 0.5  rem 0;
}
.footer-main {
  background: linear-gradient(90deg, #e3e3e4 45%, #d8d8da 78%);
  padding-top: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.footer-logo {
  width: 100px;
}
.footer-middle {
  display: flex;
  gap: 2rem;
}
.footer-link {
  text-decoration: none;
  font: normal bold 18px/1 "Jost";
  color: black;
}
.footer-link:hover {
  color: green;
}
.footer-link:active {
  font-size: 1rem;
  font-weight: bolder;
}
.footer-icon {
  font-size: 25px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
.footer-right {
  display: flex;
  gap: 10px;
}
.footer-icon:hover {
  transform: scale(1.3);
  color: green;
}

.footer-link.active-link {
  font-size: 1.5em;
  font-weight: bolder;
  color: #0c623a;
}


@media (max-width: 1060px) {
  .footer-middle {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-flex {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  .footer-main {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .footer-logo {
    width: 80px;
  }
  .footer-middle {
    gap: 1rem;
    font-size: 14px;
  }
  .footer-link {
    font-size: 14px;
  }
  .footer-icon {
    font-size: 20px;
  }
  .footer-main {
    padding: 1rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .footer-logo {
    width: 90px;
  }
  .footer-middle {
    gap: 1.5rem;
  }
  .footer-link {
    font-size: 16px;
  }
  .footer-icon {
    font-size: 22px;
  }
  .footer-main {
    padding: 2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .footer-logo {
    width: 95px;
  }
  .footer-middle {
    gap: 1.75rem;
  }
  .footer-link {
    font-size: 17px;
  }
  .footer-icon {
    font-size: 23px;
  }
  .footer-main {
    padding: 2.5rem;
  }
} */
@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

/* Main Footer Styles */
.footer-main {
  background: linear-gradient(90deg, #e3e3e4 45%, #d8d8da 78%);
  padding-top: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.footer-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #000;
  padding: 0.5rem 0; /* Fixed spacing syntax */
}

.footer-logo {
  width: 100px;
}

.footer-middle {
  display: flex;
  gap: 2rem;
}

.footer-link {
  text-decoration: none;
  font: normal bold 18px/1 "Jost";
  color: black;
}

.footer-link:hover {
  color: green;
}

.footer-link:active {
  font-size: 1rem;
  font-weight: bolder;
}

.footer-icon {
  font-size: 25px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.footer-icon:hover {
  transform: scale(1.3);
  color: green;
}

.footer-link.active-link {
  font-size: 1.5em;
  font-weight: bolder;
  color: #0c623a;
}

.footer-right {
  display: flex;
  gap: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1060px) {
  .footer-middle {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer-flex {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .footer-main {
    padding: 1rem; /* Adjusted for better mobile padding */
  }
}

@media (max-width: 480px) {
  .footer-logo {
    width: 80px;
  }

  .footer-middle {
    gap: 1rem;
    font-size: 14px;
  }

  .footer-link {
    font-size: 14px;
  }

  .footer-icon {
    font-size: 20px;
  }

  .footer-main {
    padding: 1rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .footer-logo {
    width: 90px;
  }

  .footer-middle {
    gap: 1.5rem;
  }

  .footer-link {
    font-size: 16px;
  }

  .footer-icon {
    font-size: 22px;
  }

  .footer-main {
    padding: 2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .footer-logo {
    width: 95px;
  }

  .footer-middle {
    gap: 1.75rem;
  }

  .footer-link {
    font-size: 17px;
  }

  .footer-icon {
    font-size: 23px;
  }

  .footer-main {
    padding: 2.5rem;
  }
}
