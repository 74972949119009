@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

/* Base styles */
.home {
  background: linear-gradient(90deg, #e3e3e4 45%, #d8d8da 78%);
}

.home-top {
  height: 408px;
  object-fit: contain;
}


.home-top .img-one {
  width: 100%;
  height: 460px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.82) 46%,
    rgba(255, 255, 255, 0.37307423) 66%
  );
}

.banner-btm-home {
  width: 100%;
  height: 90px;
}

/* Knowledge Section */
.knowledge-section {
  padding: 2rem 5rem;
}

.knowledge-flex {
  display: flex;
  background-color: #0c623a;
  justify-content: space-around;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.knowledge-left {
  text-align: right;
  color: white;
  padding: 2rem 4rem;
}

.knowledge-left p {
  font: normal bold 19px/29px "Jost";
}

.knowledge-title {
  font: normal bold 25px/1 "Jost";
  margin-bottom: 2rem;
}

.knowledge-right {
  background-color: #0c623a;
}

.knowledge-right img {
  width: 300px;
  padding: 2rem;
  border-radius: 40px;
}

.knowledge-left-two {
  text-align: left;
  color: white;
}

.knowledge-left-two p {
  font: normal bold 19px/29px "Jost";
}

.read-more {
  padding: 0 5rem;
  text-align: right;
}

.title-one,
.title-two {
  display: inline;
  text-decoration: none !important;
  color: #0c623a;
  margin: 2px;
}

.read-more-link {
  text-decoration: none !important;
}

/* Our remedies */

/* home section two */
.home-section-two {
  padding: 5rem 0;
  position: relative;
}

.home-main-img {
  width: 100%;
  display: block;
}

.home-main-logo {
  position: absolute;
  width: 200px;
  top: 50%;
  left: 50%;
  padding: 10px;
  border-radius: 150px;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, #e3e3e4 45%, #d8d8da 78%);
}

/* Home About section */
.home-about-section {
  display: flex;
  justify-content: center;
  text-align: center;
}

.home-about-section p {
  width: 800px;
  font: normal 18px/30px "Jost";
}

.home-read-more-btn {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  position: relative;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}

.home-read-more-btn img {
  display: block;
}

.read {
  position: absolute;
  top: 4.5rem;
  left: 50%;
  display: inline-block;
  font: normal bold 18px/1 "Jost";
  color: white;
  transform: translateX(-40%);
}

/* Media Queries */

/* Mobile devices (320px - 480px) */
@media (max-width: 480px) {
  .knowledge-section {
    padding: 10px;
  }

  .knowledge-flex {
    flex-wrap: wrap;
  }

  .home-about-section p {
    width: 100%;
    padding: 10px;
  }

  .knowledge-left {
    padding: 10px;
    text-align: left;
  }

  .knowledge-right img {
    width: 100%;
    padding: 10px;
  }
  .banner-btm-home {
    width: 100%;
    height: 90px;
    margin-top: -148px;
}
.home-top .img-one{
  margin-top: -12px;
  height: 360px;
}
}

/* Tablets (481px - 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .knowledge-section {
    padding: 20px;
  }

  .knowledge-left {
    padding: 15px;
  }

  .knowledge-right img {
    width: 250px;
    padding: 15px;
  }
}

/* Small desktops and tablets (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .knowledge-section {
    padding: 30px;
  }

  .knowledge-left {
    padding: 20px;
  }

  .knowledge-right img {
    width: 200px;
    padding: 20px;
  }
}

/* Large desktops (1025px - 1200px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .knowledge-section {
    padding: 40px;
  }

  .knowledge-left {
    padding: 25px;
  }

  .knowledge-right img {
    width: 300px;
    padding: 25px;
  }
}
@media (max-width: 1060px) {
  .knowledge-section{
    padding: 10px;
  }
  .knowledge-flex{
    flex-wrap: wrap;
  }
  .home-about-section p{
    width: 100%;
    padding: 10px;
  }
  .knowledge-left{
    padding: 10px;
    text-align: left;
  }
  .knowledge-right img{
    width: 100%;
    padding: 10px;
  }
  
  
  }